import { createStore } from 'vuex'

export default createStore({
  state: {
    menuCanvas: null,
  },
  getters: {
    menuCanvas: s => s.menuCanvas
    //dateExists: s => s.dateExists,
  },
  mutations: {
    SET_CANVAS(state, el) {
      state.menuCanvas = el
    }
  },
  actions: {
    async setCanvas({commit}, el) {
      commit('SET_CANVAS', el)
    }
  },
  modules: {
  }
})
