export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["salut i18n !!"])},
  "slide_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HERE YOU WILL FIND ONLY AUTHENTIC, NEW OR GENTLY PRE-LOVED ITEMS FROM THE MID-PREMIUM, PREMIUM OR LUXURY BRANDS."])},
  "slide_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WE OFFER YOU ACCESS TO LUXURY BRANDS WITH A DISCOUNT OF UP TO 90% !"])},
  "slide_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOLY CHIC CAN HELP YOU CLEAN UP YOUR CLOSET AND REFILL IT SUSTAINABLY"])},
  "slide_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ORIGINAL SELECTION AND AUTHENTICITY GUARANTEED!"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DISCOVER"])},
  "about_resale_studio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOLY CHIC Studio"])},
  "when_nowhere_hang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“nothing to wear, yet no space in your closets.”"])},
  "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TERMS"])},
  "of_admission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OUR TERMS/OUR POLICY"])},
  "when_clothes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When things have exhausted your credit of delight"])},
  "founder_name_br": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evghenia<br/>  Gruzdeva "])},
  "founder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evghenia Gruzdeva "])},
  "founder_and_permanent_br": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Founder and managing Director of Holy Chic Studio  "])},
  "founder_and_permanent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Founder and managing Director of Holy Chic Studio "])},
  "title_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How it all started:"])},
  "article1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["During my short term maternity leave, I decided to use my free time to test a business idea I’ve been toying with in my mind for a while. Our new headquarters, my vacant office, 16 m2 square meters on the 4th floor of a walk up building, two rails and a shoe closet. This was the birth of the Holy Chic Studio, just a week before I gave birth to our son."])},
  "bio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIO"])},
  "bio_content1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An entrepreneur with over 18 years experience in consumer marketing, PR and communications. In 2008 Evghenia launched Head Made, a ground breaking Branding and Marketing Agency in Moldova. Her agency was the first to recognize the potential of influencer marketing and successfully implement it within her clients’ marketing strategies."])},
  "bio_content2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her professional foray into fashion began five years ago, as she was exploring her own passion for sustainability and how it can relate to premium and luxury fashion. In 2018 she launched Holy Chic, an offline resale platform for luxury fashion, that reached double digit annual growth by 2020. Holy Chic is currently the leading resale network for premium and luxury fashion in Moldova."])},
  "article2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The idea to launch a resale platform was prompted by my own needs. I can't claim it all to myself, consignment platforms have been growing in popularity on the Western markets for over a decade. Our local market, however, had nothing to offer but online resale forums and personals."])},
  "article3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "article4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In 2018, no-one heard about resale, so we had to create the market and reshape the customers attitudes towards resale from scratch. We had to struggle against the stereotype of the idea of consignment service, and communicate the real value of the merchandise we offered."])},
  "article5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The hardest task, however, was to develop a database of suppliers. A woman's wardrobe is a personal space. Strangers are often not allowed, unless they earn a woman’s trust. Word of mouth played a key role, satisfied suppliers recommended us to their friends, allowing us to grow and be discovered."])},
  "article6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just a few months after opening, with customer demand exceeding all expectations, we had no choice but to move to a larger office. The overall feedback was sincere and inspiring so I realized we had no choice but to grow even bigger! At the moment Holy Chic Studio occupies 220 m2 square meters in the historical city center and we saw our turnover triple just this year alone. The project has never attracted third-party investment, nor has it received grants. We never had to take out a bank loan and for the entire period of Holy Chic we haven’t had a single unprofitable month."])},
  "article7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zero carbon footprint:"])},
  "article8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowadays, it is impossible to remain ambivalent about the effect of consumption on our environment. I’ve become particularly aware of this after the birth of my son. Involuntarily, I’ve begun to consider what kind of world we will be leaving behind for future generations and what can be done to not just sustain this world but make it better. I prefer to think big, but start small, with “my own vegetable garden.” I know that my business can make a small but meaningful, daily contribution to the fight against overproduction in fashion."])},
  "article9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are engaged in the most environmentally friendly part of the fashion industry. We do not produce anything and do not buy anything, respectively, we have no waste and or surplus stock. We offer the reuse of goods and focus on high-quality merchandise that will be enjoyed by our customers for a very long time!"])},
  "article10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In over five years of the Holy Chic studio’s existence, we have given a second life to 4,000 dresses, 7,500 pairs of shoes and 3,400 bags! In terms of resources saved, these are millions of tons of water, kilojoules of energy and liters of fuel. Such statistics have to be spoken about and celebrated. That said, I would not reduce the essence of our service only to sustainability."])},
  "article11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klondike for gambling fashionistas:"])},
  "article12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is a serious shortage of brands presented in Chisinau, MD. In this market environment we are a breath of fresh air for women who love exclusive designer merchandise. Only in Holy Chic you can find archival collections of brands like Celine, Maison Martin Margiela, Christian Dior and Chanel among others. Here you can find a rare Hermes bag or shawl or a pair of the trendiest sandals of the current season—all at a big discount. While we specialize in luxury fashion, we try not to limit ourselves to a single market segment. In addition to high end luxury fashion, you can shop our selection of middle-premium fashion. If this is not enough, you can always visit us to shop or simply be inspired by a unique selection of rare, collectible vintage and sought after collaborations."])},
  "article13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "article14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our long time suppliers have become experts themselves, now when they purchase something new, they make an accurate estimate at what price it can be re-sold at Holy Chic. They treat their treasures with greater care and once they’ve enjoyed them, they know we’ll find a new loving home for their purchases. Inadvertently we have created and spread a certain degree of awareness to our partners and customers, and created a shift in a paradigm and attitude toward shopping."])},
  "article15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We believe in miracles: "])},
  "article16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you only knew what miracles happen in Holy Chic studio. The recent graduates we’ve dressed in Christian Dior gowns! Countless customers who have come looking for a designer bag, to also fall in love with their dream pair of shoes! We’ve Manolo Blahnik obsessed customers, who not unlike the main character of the hit show Sex & The City, Carrie Bradshaw become major fashionistas in their own way. Only at Holy Chic, you can find a Chanel bag at a fraction of the price, an item that may have been an inaccessible dream before us. All these dreams can come true at Holy Chic!"])},
  "article_final": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The work of the most talented designers, craftsmen and creatives, may have been doomed to lie on a top shelf of someones’ closet, can now inspire and be enjoyed by someone special. If you are facing the same problems as hundreds of our suppliers and customers, we are always here for you. All our terms of service are described in detail on our website www.holychic.club . We also recommend that you take time to subscribe to our Instagram holy_chic.md and join our community of fashion enthusiasts. We want to inspire you to invest your money on travel, discovery, education, family, personal growth and whatever makes you happy! Save money and contribute to protecting the global environment, little by little while shopping our unique selection of fashion, fine jewelry and accessories. If you no longer find yourself in love with your clothes, bring them to us and we will find them a new perfect match and even something new for you to fall in love with."])},
  "how_resale_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About how resale will save the world"])},
  "brands_authentic_br": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brands<br/> and Authenticity"])},
  "brands_authentic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brands and Authenticity"])},
  "take_dont_take": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brands we accept for consignment our selection and authenticity process"])},
  "wish_black_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Wishlist, Blacklist and the Holy Chic crusade against FAKES "])},
  "formula_br": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" OUR <br/> CONSIGNMENT FORMULA"])},
  "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OUR CONSIGNMENT FORMULA"])},
  "formula_svg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(FINAL SELLING PRICE) - (25% HOLY CHIC COMMISSION) - (12% INCOME TAX) = YOUR PROFIT"])},
  "formula_svg_final": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Example 1000 MDL 100-25% = 750 MDL 750-12%=660 MDL"])},
  "final_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["final payment to the vendor"])},
  "selling_price_commission_taxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final sale price, our consignment % and taxes"])},
  "find_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to find us"])},
  "address_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moldova, city Chisinau, street Mitropolit Banulescu-Bodoni, 27 (Entrance from Bucurest street), Index 2012"])},
  "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holy Chic Studio Opening Hours"])},
  "schedule_values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every day, from 10.00 to 20.00"])},
  "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
  "about_par_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are is an original resale concept founded by Evghenia Gruzdeva. Since its inception 5 years ago, we’ve worked to become the leader on the Moldovan market."])},
  "about_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our service allows you to buy and/or sell designer items for the women's wardrobe."])},
  "about_par_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We offer a responsible and unique shopping experience for our customers and an opportunity for our partners to give new life to your designer fashion and jewelry at a profit! Our team is driven by shared love and passion for beauty and above all a sustainable way to enjoy shopping. We are the solution to the internal irony when you’ve “nothing to wear, yet no space in your closets.”"])},
  "about_par_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOLY CHIC is a cosy environment with retail space in central Chisinau with a network of global resale partners and above all loyal customers that trust and support our visions."])},
  "about_par_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to our world of Re-Sale, an opportunity to discover and explore fashion responsibly!"])},
  "familiarize_rules_br": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OUR <br> TERMS"])},
  "go_back_br": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BACK<br/>HOME"])},
  "rules_subtitle_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whether you are looking for unique designer pieces, or hoping to find a loving home for your designer fashions, Holy Chic is here to help! We have set a few ground rules that help us earn your trust. When you’re no longer in love with your special piece(s) we are here to find them a new loving home."])},
  "rules_par_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before you decide to entrust us with this mission, Please read carefully and consider the following criteria!"])},
  "rules_subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condition"])},
  "rules_par_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We prefer New, Never Worn or almost New pieces! We always make exception for collectible and vintage pieces, so please contact us if you’ve any questions.As a general rule we only sell items that don’t show visible wear and are in a great condition. "])},
  "rules_subtitle_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relevance"])},
  "rules_par_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our studio aims to offer only relevant and current collections. The rule of thumb, we remind you to ask yourself if you would buy your own item, if you were shopping yourself. If the answer is “Yes” we can’t wait to work with you!"])},
  "rules_subtitle_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brands"])},
  "rules_par_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designer fashions are often preferred. They tend to have a longer life span due to quality and relevance and can find a loving home many times over. Please take good care of your designer fashion and protect your investment.We prefer to focus on the Mid-Premium, Premium and Luxury fashion Only."])},
  "rules_subtitle_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preliminary Review"])},
  "rules_par_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If your item(s) meet the above criteria, take a few photographs with your phone and send them to us for preliminary consideration. Photographs should demonstrate the brand tags, the actual piece(s), fabric contents and any elements you believe we may find relevant. Once you’re ready to share your images, contact our managers directly. This will be sufficient to help us advise you further."])},
  "rules_par_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will make a preliminary selection and give you an estimate for your items. Please, be aware that if you choose to work with us, you’ll be signing a consignment agreement that allows us to photograph, record and share your items on social networks if necessary. It goes without saying that we work with outmost respect for your items and are always discreet."])},
  "rules_par_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brands we work with/Brand Wishlist"])},
  "rules_par_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We give priority consideration to mid-premium, premium and luxury products. The following brands are always welcome at Holy Chic."])},
  "rules_conditions_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consignment Process:"])},
  "rules_conditions_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holy Chic accepts merchandise for consignment for a period of two (2) months. The sale price is pre-agreed with you and is fixed in a written agreement between Holy Chic and You."])},
  "rules_conditions_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This agreement is drawn up and signed between us following the review and authentication of the merchandise. Our experts evaluate the merchandise within the following criteria in 7 (seven) steps:"])},
  "rules_conditions_sub_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relevance of the merchandise"])},
  "rules_conditions_sub_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condition of the merchandise"])},
  "rules_conditions_sub_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actual price of the merchandise when initially released"])},
  "rules_conditions_sub_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price of the merchandise at discount (if relevant)"])},
  "rules_conditions_sub_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price comparisons on the EU and USA resale markets"])},
  "rules_conditions_sub_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The price of the merchandize (if relevant) through professional private buyers"])},
  "rules_conditions_sub_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We also always consider special/ rare collaborations and/ or vintage status of the merchandise"])},
  "rules_conditions_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We may choose to contact an independent authentication service in case of certain items. If we choose to do so, the Holy Chic manager will contact you for approval of this service. If the merchandise is found to be authentic, we will deduct the cost of authentication from your sale(s). If the merchandise is not found to be authentic, the cost of this process will be your personal responsibility. You will be asked to collect your items and you will have to cover the cost of the authentication. "])},
  "rules_conditions_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important:"])},
  "rules_conditions_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We reserve the right to record  and (selectively) publish your merchandise on our social networks, if it promotes the sale(s) of the selected item(s)."])},
  "rules_conditions_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment for the sold item(s) is made to you in cash at our studio and the release of funds is always fixed by the proof your signature."])},
  "rules_conditions_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If we fail to sell your item(s) within a period of 2 months, we will contact you to consider a markdown of 20% off the previously agreed sale price and to give your merchandise another chance at a new life."])},
  "rules_conditions_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consignment Returns:"])},
  "rules_conditions_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If we are unable to sell your items within a month after the final discount we make a final evaluation, we will arrange for the return of the merchandise to you. At this time you will be notified by your chosen means of contact such as Telephone/SMS/E-mail."])},
  "rules_conditions_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additionally Holy Chic can offer you an option to return your merchandise with our courier service, free of charge. Since our storage facilities are not designed for long-term storage, we kindly ask that you do NOT delay the pick up of your item(s) and/ or arrange for their return with us, as soon as possible after you’ve been notified that the merchandise is being returned to you unsold."])},
  "rules_conditions_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our client service manager will follow up with you to arrange for the return of the merchandise for a period of three weeks. If we don’t hear back from you or if you fail to pick your items within the three week period, we reserve an option to transfer your merchandise to a charitable organization of our choosing. Will will keep a proof of this transfer and we will be happy to present it to you upon demand."])},
  "rules_conditions_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your mindful approach to your wardrobe and your careful consideration for our Planet."])},
  "send_photos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, <br/> contact us <br/> for special considerations."])},
  "from_final_cost_br": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holy Chic commission is 25% of the final selling price. "])},
  "income_tax_br": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Will be retained to cover the income tax* <br/>from the remaining sum, <br/> after we’ve retained our commission."])},
  "income_tax_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Income tax. Article 90-1. The law requires us to withholding tax from certain types of income. Each commission agent withholds tax in the amount of 12% of payments made in favor of an individual."])},
  "work_brands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brands we<br/> work with"])},
  "founder_par_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When we opened Holy Chic three years ago, I couldn't even imagine how eagerly it would be awaited!"])},
  "founder_par_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The pilot project format was housed in my former office, which was just 12 square meters!"])},
  "founder_par_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We set up two clothing racks and put together a shoe cabinet. Within a couple of months, we had to move to a bigger space. The demand was enormous, the feedback was very inspiring, and the response was so charged and genuine that I realized we had to grow."])},
  "wishlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wishlist"])},
  "wishlist_par_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We prioritize working with the relevant merchandise of the Middle-Up, Premium and Luxury market segments. You can use the brand map below to guide you or contact our managers for advice. We do make accessions in case of special brand collaborations and with selected vintage pieces."])},
  "ban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "wishlist_par_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "blacklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blacklist"])},
  "ban_par": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holy Chic does not currently work with mass market brands, hand-made merchandise without labels or unknown brands. This is the list of brands not represented at the Holy Chic Resale Studio:"])},
  "etc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
}