<template>
  <div class="menu">
    <div class="offcanvas offcanvas-start menu__offcanvas " id="myOffcanvas">
      <header class="menu__offcanvas-header">
        <div class="menu__offcanvas-header-container flex justify-end py-7 mx-auto ">
          <button class="menu__offcanvas-close" @click="$store.getters.menuCanvas.toggle()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M4.34 2.93l12.73 12.73-1.41 1.41L2.93 4.35z"/>
              <path d="M17.07 4.34L4.34 17.07l-1.41-1.41L15.66 2.93z"/>
            </svg>
          </button>
        </div>
      </header>
      <nav class="menu__nav mx-auto mt-3">
        <ul>
          <li>
            <router-link to="/about" @click="$store.getters.menuCanvas.toggle()" class="hover:text-red">
              {{ $t('about') }}
            </router-link>
          </li>
          <li>
            <router-link to="/rules" @click="$store.getters.menuCanvas.toggle()" class="hover:text-red">
              {{ $t('rules') }}
            </router-link>
          </li>
          <li>
            <router-link to="/brands" @click="$store.getters.menuCanvas.toggle()" class="hover:text-red">
              {{ $t('brands_authentic') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{ path: '/rules', hash: '#formula' }" @click="$store.getters.menuCanvas.toggle()" class="hover:text-red">
              {{ $t('formula') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{ path: '/', hash: '#contacts' }" @click="$store.getters.menuCanvas.toggle()" class="hover:text-red">
              {{ $t('contacts') }}
            </router-link>
          </li>
          <li>
            <locale-switcher></locale-switcher>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import { Offcanvas } from 'bootstrap';
export default {
  name: 'Header',
  props: {
    isHomeHeader: {
      type: Boolean,
      default: function (){
        return false
      }
    }
  },

  data(){
    return{
      //menuCanvas: null,
    }
  },

  mounted() {
     var myOffcanvas = document.getElementById('myOffcanvas');
    this.$store.dispatch('setCanvas', new Offcanvas(myOffcanvas));
  },

  methods:{
    toggleOffcanvas(){
     // this.$store.getters.menuCanvas.toggle();
    }
  }

}
</script>

<style scoped lang="scss">
  .agile__slides{
    div{
      width: 10px;
      height: 10px;
      background: red;
    }
  }
</style>
